body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.MuiDateRangePickerDay-day.Mui-selected {
  background-color: #252f3e !important;
}

.MuiDateRangePickerDay-rangeIntervalDayHighlight {
    background-color: #464e5b !important;
}

.MuiDateRangePickerDay-rangeIntervalDayHighlight .MuiDateRangePickerDay-notSelectedDate {
    color: #fff;
}

/* .MuiToolbar-root{
  min-height: 0px!important;
} */

#main-content {
  padding-left: 15px;
}

th {
  white-space: nowrap;
}
